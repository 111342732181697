<script setup lang="ts">
import { SearchPlus } from "@ui/icons/regular";
import Icon from '../icons/Icon.vue';
import { placeholderImage, PLACEHOLDER_IMG } from './imageSettings';

const props = withDefaults(defineProps<{
  src?: string;
  alt?: string;
  border?: boolean;
  zoom?: boolean;
  showZoom?: boolean;
  contain?: boolean;
  lazy?: boolean;
  placeholder?: string;
}>(), {
  alt: 'CUE',
  border: true,
  zoom: false,
  showZoom: true,
  contain: false,
  lazy: true,
  placeholder: '',
});

function getSrc() {
  return props.src || props.placeholder || placeholderImage.value;
}

const url = ref(getSrc());
watch(() => props.src, () => {
  url.value = getSrc();
});

function onError() {
  url.value = url.value === placeholderImage.value ? PLACEHOLDER_IMG: props.placeholder;
}
</script>

<template lang="pug">
.image-container.br-14(v-if="url" :class="{ border, zoom, contain }")
  img.image.s-100p.br-14(:src="url" :alt="alt" :loading="lazy ? 'lazy' : 'eager'" @error.native="onError")
  Icon.icon-zoom-in.size-20.m-12(v-if="zoom && showZoom" :name="SearchPlus")
</template>

<style scoped lang="scss">
.image-container {
  overflow: hidden;
  position: relative;

  &.border {
    border: 1px solid #E8E9ED;
  }
  
  .image {
    object-fit: cover;
  }

  &.contain {
    .image {
      object-fit: contain;
    }
  }

  &.zoom {
    cursor: zoom-in;

    .image {
      transform-origin: center center;
      transition: .3s ease-in-out;
      transition-property: transform;
      
      &:hover {
        transform: scale(1.15);
      }
    }
  }
}

.icon-zoom-in {
  position: absolute;
  color: white;
  top: 0;
  right: 0;
}
</style>